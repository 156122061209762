// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import SignupPage from './pages/SignupPage.js';
import LoginPage from './pages/LoginPage.js';
import ServicePage from './pages/ServicePage.js';
import ContactPage from './pages/ContactPage';
import Privacy from './pages/PrivacyPolicyModal.js';
import Terms from './pages/TermsAndConditionsModal.js';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          {/* Add more routes for other pages */}
          <Route path="/SignupPage" element={<SignupPage />} /> {/* Add this line for the sign-up page */}
          <Route path="/LoginPage" element={<LoginPage />} /> {/* Add this line for the sign-in page */}
          <Route path="/ServicePage" element={<ServicePage />} /> {/* Add this line for the sign-in page */}
          <Route path="/ContactPage" element={<ContactPage />} /> {/* Add this line for the sign-in page */}
          <Route path="/PrivacyPolicyPage" element={<Privacy />} /> {/* Add this line for the sign-in page */}
          <Route path="/TermsAndConditionsPage" element={<Terms />} /> {/* Add this line for the sign-in page */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
