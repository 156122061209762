// TermsAndConditionsPage.js
import React,  { useEffect }  from 'react';
import {NavBar} from './NavBar.js';
import Footer from './FotterPage.js';
import './css/navBar.css';
import './css/HomePage.css';
import './css/firsthome-css.css';
import PrivacyPolicyPage from './PrivacyPolicyModal.js';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
const TermsAndConditionsPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-container">
      <NavBar />
      <div className="container mt-5">
        <h1>SMS Terms & Conditions</h1>

        <h2>1. SMS Consent Communication:</h2>
        <p>
          The Phone Numbers obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.
        </p>

        <h2>2. Types of SMS Communications:</h2>
        <p>
          If you have consented to receive text messages from Rainbow Travel, LLC, you may receive messages related to the following:
        </p>
        <ul>
          <li>Marketing (deals and Specials)</li>
          <li>Customer Care (General questions about reservations)</li>
        </ul>

        <h2>3. Message Frequency:</h2>
        <p>
          Message frequency may vary depending on the type of communication. You may receive up to 20 daily SMS messages depending on your engagement.
        </p>

        <h2>4. Potential Fees for SMS Messaging:</h2>
        <p>
          Please note that standard message and data rates may apply, depending on your carrier’s pricing plan. These fees may vary if the message is sent domestically or internationally.
        </p>

        <h2>5. Opt-In Method:</h2>
        <p>
          You may opt-in to receive SMS messages from Rainbow Travel, LLC in the following way:
        </p>
        <ul>
          <li>By submitting a website form</li>
        </ul>

        <h2>6. Opt-Out Method:</h2>
        <p>
          You can opt out of receiving SMS messages at any time. To do so, simply reply <strong>"STOP"</strong> to any SMS message you receive. Alternatively, you can contact us directly to request removal from our messaging list.
        </p>

        <h2>7. Help:</h2>
        <p>
          If you are experiencing any issues, you can reply with the keyword <strong>"HELP"</strong>. Or, you can get help directly from us at <strong>+1 (313) 582-3322</strong>.
        </p>

        <h2>Additional Options:</h2>
        <p>
          If you do not wish to receive SMS messages, you can choose not to check the SMS consent box on our forms.
        </p>

        <h2>8. Standard Messaging Disclosures:</h2>
        <ul>
          <li>Message and data rates may apply.</li>
          <li>You can opt-out at any time by texting <strong>"STOP"</strong>.</li>
          <li>
            For assistance, reply with the word <strong>"HELP"</strong> or contact Rainbow Travel, LLC directly at <strong>+1 (313) 582-3322</strong> or via email at <a href="mailto:info@rtsfares.com">info@rtsfares.com</a>.
          </li>
          <li>
            To learn more about our Privacy Policy, visit{" "}
            <Link to="/PrivacyPolicyPage" className="text-blue">
              Privacy Policy
            </Link>.
          </li>
          <li>Message frequency may vary.</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;