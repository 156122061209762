// Footer.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/footer-css.css';
import { FacebookIcon, InstagramIcon } from './SocialIcons.js';
import logoImage from './img/logo.jpeg';
import PrivacyPolicyPage from './PrivacyPolicyModal.js';
import TermsAndConditionsPage from './TermsAndConditionsModal.js';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-3 mb-4 footer-divs">
            {/* Logo or other content */}
          </div>
          <div className="col-md-3 mb-4 footer-divs">
            <h5>Working Hours</h5>
            <p className="your-paragraph-class">Monday - Friday: 9 AM - 5 PM</p>
            <p className="your-paragraph-class">Saturday: per appointment only</p>
            <p className="your-paragraph-class">Sunday: closed</p>
          </div>
          <div className="col-md-3 mb-4 footer-divs">
            <h5>Office Location</h5>
            <p className="your-paragraph-class">24619 Ford Rd, Dearborn, MI 48128, United States</p>
            <p className="your-paragraph-class">Office number: +1 313-582-3322</p>
          </div>
        </div>

        <div className="mb-4">
          <h5>Follow Us</h5>
          <div className="d-flex justify-content-center">
            <a href="https://www.facebook.com/rtsfares/" target="_blank" rel="noopener noreferrer" className="me-3">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/rtsfares/" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
          </div>
        </div>

        <div>
          <h5>Privacy Policy</h5>
          <p className="your-paragraph-class">
            Read our{' '}
            <Link to="/PrivacyPolicyPage">Privacy And Policy.</Link>
          </p>
        </div>
        <div>
          <h5>Terms and Conditions</h5>
          <p className="your-paragraph-class">
            Read our{' '}
            <Link to="/TermsAndConditionsPage">Terms And Conditions.</Link>
          </p>
        </div>

        <div>
          <h5>Report a Problem</h5>
          <p className="your-paragraph-class">
            If you encounter any issues, please{' '}
            <a href="mailto:info@rtsfares.com">contact support</a>.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;