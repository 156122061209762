// PrivacyPolicyPage.js

import React, { useEffect }  from 'react';
import {NavBar} from './NavBar.js';
import Footer from './FotterPage.js';
import './css/navBar.css';
import './css/HomePage.css';
import './css/firsthome-css.css';
const PrivacyPolicyPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-container">
      <NavBar />
    
    <div className="container mt-5">
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>Welcome to Rainbow Travel.</p>
      <p>Rainbow Travel (“us”, “we”, or “our”) operates rtsfares.com (hereinafter referred to as “Service”).</p>

      <h2>Information Collection and Use</h2>
      <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

      <h2>Types of Data Collected</h2>
      <h3>Personal Data</h3>
      <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, Province, ZIP/Postal code, City</li>
        <li>Cookies and Usage Data</li>
      </ul>

      <h2>Use of Data</h2>
        <p>Rainbow Travel uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain our Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our Service</li>
          <li>To monitor the usage of our Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        <h2>Privacy Assurance</h2>
        <p>We value your privacy and assure you that:</p>
        <ul>
          <li>We do not sell or share any SMS consent or personal information to third parties or affiliates for SMS marketing purposes.</li>
        </ul>

        <h2>Transfer of Data</h2>
        <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>

        <h2>Disclosure of Data</h2>
        <p>We may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Rainbow Travel</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>

        <h2>Security of Data</h2>
        <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
      {/* Add the rest of the Privacy Policy content here */}
    </div>
    <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;